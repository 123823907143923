import React, { useEffect } from "react";
import Layout from "Layout";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import Loader from "components/Notifications/Loader";
import {
  executeGetPaymentStatus,
  useSubmitPaymentDetails,
} from "apis/students";
import { removeTimestamp } from "utils/index";

const PaymentConfirmation: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const txnref = searchParams.get("txnref");
  const amount = searchParams.get("amount");
  const merchant_code = process.env.REACT_APP_MERCHANT_CODE;

  const { data, isLoading } = useQuery({
    queryKey: ["paymentStatus"],
    queryFn: () =>
      executeGetPaymentStatus(
        merchant_code!,
        txnref!,
        parseInt(amount!, 10)
      ),
  });

  const { mutate: submitPayment } = useSubmitPaymentDetails();

  useEffect(() => {
    if (data?.ResponseCode === "00") {
      const paymentDetails = {
        txnRef: removeTimestamp(txnref),
        paymentId: data.PaymentId,
      };

      submitPayment(paymentDetails, {
        onSuccess: () => {
          toast.success("Payment details submitted successfully.");
        },
        onError: () => {
          toast.error("Error submitting payment details.");
        },
      });

      console.log("Payment status data successful:", data);
    } else {
      console.log("Payment status data failed:", data);
    }
  }, [data, submitPayment, txnref, amount, merchant_code]);

  if (isLoading) {
    return <Loader />;
  }

  if (data?.ResponseCode === "00") {
    return (
      <Layout>
        <div className="min-h-screen flex flex-col justify-center items-center bg-gray-50">
          {/* Main content for successful payment */}
          <div className="bg-white p-6 rounded-lg shadow-lg text-center max-w-2xl w-full">
            <div className="flex justify-center mb-4">
              <div className="w-12 h-12 rounded-full bg-green-100 flex items-center justify-center">
                <svg
                  className="w-6 h-6 text-green-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
            </div>
            <h2 className="text-2xl font-semibold text-gray-800">Thank you</h2>
            <p className="text-lg text-gray-600 mt-2">
              Your payment has been received
            </p>
            <p className="text-sm text-gray-500 mt-1">
              You can print your payment receipt{" "}
              <a href="https://www.google.com" className="text-red-500">
                here
              </a>
            </p>

            {/* Payment details */}
            <div className="mt-6 bg-gray-100 rounded-lg p-4 text-left">
              <h3 className="text-lg font-semibold text-gray-800 mb-4">
                Payment details
              </h3>
              <div className="flex justify-between text-sm text-gray-600 mb-2">
                <p>Payment item:</p>
                <p className="font-medium">Registration Fee</p>
              </div>
              <div className="flex justify-between text-sm text-gray-600 mb-2">
                <p>Payment method:</p>
                <p className="font-medium">Web Pay</p>
              </div>
              <div className="flex justify-between text-sm text-gray-600 mb-2">
                <p>Date:</p>
                <p className="font-medium">Oct 15, 2024</p>
              </div>
              <div className="flex justify-between text-sm text-gray-600 mb-2">
                <p>Amount:</p>
                <p className="font-medium">20,000</p>
              </div>
              <div className="flex justify-between text-sm text-gray-600">
                <p>Payment Reference:</p>
                <p className="font-medium">CHOFJENIENRIE0</p>
              </div>
            </div>
          </div>

          {/* Footer */}
          <footer className="mt-10">
            <p className="text-sm text-gray-500">
              Powered by{" "}
              <span className="font-semibold text-red-500">Paypro</span>
            </p>
          </footer>
        </div>
      </Layout>
    );
  }

  // Failed payment screen
  return (
    <Layout>
      <div className="min-h-screen flex flex-col justify-center items-center bg-gray-50">
        {/* Main content for failed payment */}
        <div className="bg-white p-6 rounded-lg shadow-lg text-center max-w-2xl w-full">
          <div className="flex justify-center mb-4">
            <div className="w-12 h-12 rounded-full bg-red-100 flex items-center justify-center">
              <svg
                className="w-6 h-6 text-red-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>
          <h2 className="text-2xl font-semibold text-gray-800">
            Payment Failed
          </h2>
          <p className="text-lg text-gray-600 mt-2">
            Sorry, your payment was unsuccessful.
          </p>
          <p className="text-sm text-gray-500 mt-1">
            Please try again, or contact support if the problem persists.
          </p>

          <div className="mt-6 bg-gray-100 rounded-lg p-4 text-left">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">
              Payment details
            </h3>
            <div className="flex justify-between text-sm text-gray-600 mb-2">
              <p>Transaction reference:</p>
              <p className="font-medium">{txnref}</p>
            </div>
            <div className="flex justify-between text-sm text-gray-600 mb-2">
              <p>Amount:</p>
              <p className="font-medium">{amount}</p>
            </div>
          </div>
        </div>

        {/* Footer */}
        <footer className="mt-10">
          <p className="text-sm tex t-gray-500">
            Powered by{" "}
            <span className="font-semibold text-red-500">Paypro</span>
          </p>
        </footer>
      </div>
    </Layout>
  );
};

export default PaymentConfirmation;
