import React, { useEffect, useState } from "react";
import AccessAlarmOutlinedIcon from "@material-ui/icons/AccessAlarmOutlined";
import Layout from "Layout";
import { Button } from "components/Form";
import useDisclosure from "hooks/useDisclosure";
import { executeGetInvoices } from "apis/students";
import toast from "react-hot-toast";
import { useAppSelector } from "hooks/redux";
import { useNavigate } from "react-router-dom";
import Loader from "components/Notifications/Loader";

interface SummaryCardProps {
  icon: React.ReactNode;
  label: string;
  desc: string;
  value: string;
  dueDate: string;
  bgColor: string;
  iconColor: string;
  action: string;
  id: string;
}

const DuePayments = () => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [invoices, setInvoices] = useState<any[]>([]);
  const user = useAppSelector((state) => state.account.user);

  const handleGetInvoices = async () => {
    try {
      onOpen();
      const result = await executeGetInvoices(user.id!);
      setInvoices(result.data);
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      onClose();
    }
  };

  useEffect(() => {
    handleGetInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Filter invoices to include only unpaid ones
  const unpaidInvoices = invoices?.filter(
    (invoice) => invoice.status === "unpaid"
  );

  return (
    <Layout>
      <div className="flex flex-col items-center">
        <h1 className="text-lg mr-auto mb-6 mt-6">All Outstanding Payments</h1>
        <div className="w-3/4 mr-auto grid grid-cols-1 md:grid-cols-3 gap-6">
          {isOpen ? (
            <Loader />
          ) : (
            unpaidInvoices?.map((invoice) => (
              <SummaryCard
                key={invoice.id}
                id={invoice.invoiceNumber}
                icon={<AccessAlarmOutlinedIcon />}
                label={invoice.fee.name || "Unknown"}
                desc={invoice.fee.description || "No description"} // Fallback text if `description` is not available
                value={invoice.amount ? invoice.amount.toLocaleString() : "0"} // Format amount with thousands separators
                dueDate={new Date(invoice.dueDate).toLocaleDateString()} // Format date to a readable format
                bgColor="bg-white" // You can customize this if you want to change the background color dynamically
                iconColor="bg-red-300" // Customize icon color if needed
                action="Pay Now" // You can modify this based on invoice data if required
              />
            ))
          )}
        </div>
      </div>
    </Layout>
  );
};

const SummaryCard: React.FC<SummaryCardProps> = ({
  icon,
  label,
  desc,
  value,
  dueDate,
  bgColor,
  iconColor,
  action,
  id,
}) => {
  const navigate = useNavigate();
  const handlePayment = () => {
    navigate(`/payment-summary/${id}`);
  };
  return (
    <div
      className={`flex flex-col items-start ${bgColor} p-6 rounded-lg shadow-md w-full mt-4`}
    >
      <div
        className={`${iconColor} w-12 h-12 text-white rounded-full flex items-center justify-center mb-4`}
      >
        {icon}
      </div>

      <div className="text-left mb-4">
        <h2 className="text-lg text-black font-semibold">{label}</h2>
        <span className="text-sm text-gray-500">{desc}</span>
      </div>

      <div className="text-left mb-4 mt-6">
        <p className="text-2xl text-black font-semibold">₦{value}</p>
        <p className="text-sm text-gray-500">{dueDate}</p>
      </div>

      <Button
        label={action}
        className="bg-orange-400 w-full text-white px-6 py-2 rounded-md"
        onClick={() => handlePayment()}
      />
    </div>
  );
};

export default DuePayments;
