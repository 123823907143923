import { useState } from "react";
import { IoCopyOutline } from "react-icons/io5";
import { useLocation } from "react-router-dom";

const QuicktellerPaymentPage = () => {
  const [copied, setCopied] = useState(false);

  const location = useLocation();
  const { referenceId, name, studentId, paymentUrl } = location.state;

  console.log({ copied });

  const handleCopy = async () => {
    await navigator.clipboard.writeText(referenceId!);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="max-w-2xl mx-auto px-4 py-8">
      <h1 className="text-2xl md:text-3xl font-bold text-navy-900 mb-2">
        Quickteller.com <span className="text-gray-400">Payment</span>
      </h1>

      <p className="text-gray-700 mb-4">
        Please follow the instructions below to make a payment on
        Quickteller.com
      </p>

      <a
        href={paymentUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 hover:text-blue-600 mb-12 block"
      >
        {paymentUrl}
      </a>

      <div className="flex justify-between items-center mb-16">
        <div className="flex flex-col items-center">
          <div className="w-16 h-16 rounded-full bg-red-100 flex items-center justify-center mb-4">
            <span className="text-2xl text-red-400">1</span>
          </div>
          <h3 className="font-semibold mb-2">Copy</h3>
          <p className="text-sm text-center text-gray-600">
            Click to copy your
            <br />
            Payment Reference
            <br />
            ID
          </p>
        </div>

        <div className="flex flex-col items-center">
          <div className="w-16 h-16 rounded-full bg-yellow-50 flex items-center justify-center mb-4">
            <span className="text-2xl text-yellow-400">2</span>
          </div>
          <h3 className="font-semibold mb-2">Redirect</h3>
          <p className="text-sm text-center text-gray-600">
            Click on the next
            <br />
            button after copying
            <br />
            your Payment Reference ID
          </p>
        </div>

        <div className="flex flex-col items-center">
          <div className="w-16 h-16 rounded-full bg-green-100 flex items-center justify-center mb-4">
            <span className="text-2xl text-green-400">3</span>
          </div>
          <h3 className="font-semibold mb-2">Pay</h3>
          <p className="text-sm text-center text-gray-600">
            Enter your{" "}
            <span className="text-red-500">
              Payment
              <br />
              Reference ID
            </span>{" "}
            as your
            <br />
            <span className="text-red-500">Customer ID</span> and
            <br />
            click continue
          </p>
        </div>
      </div>

      <div className="bg-gray-50 p-6 rounded-lg mb-8">
        <h2 className="text-lg font-semibold mb-4">Payment Reference ID:</h2>
        <div className="bg-white p-4 rounded-lg flex justify-between items-center border">
          <span className="font-mono text-lg">{referenceId}</span>
          <button
            onClick={handleCopy}
            className="text-gray-500 hover:text-gray-700"
          >
            <IoCopyOutline size={24} />
          </button>
        </div>

        <div className="mt-6 space-y-2">
          <p>
            Name: <span className="text-red-500">{name}</span>
          </p>
          <p>
            StudentID: <span className="text-red-500">{studentId}</span>
          </p>
        </div>
      </div>

      <button
        onClick={() => window.open(paymentUrl, "_blank")}
        className="w-full bg-red-600 text-white py-4 rounded-lg hover:bg-red-700 transition-colors mb-4"
      >
        Next
      </button>

      <button className="w-full text-red-600 font-semibold">
        Print for Later
      </button>
    </div>
  );
};

export default QuicktellerPaymentPage;
