export const readFile = (file: File | Blob, type: "text" | "base64" = "text") =>
  new Promise((res, rej) => {
    const reader = new FileReader();
    if (type === "text") reader.readAsText(file);
    if (type === "base64") reader.readAsDataURL(file);
    reader.addEventListener("load", () => {
      res(reader.result);
    });

    reader.addEventListener("error", () => {
      rej("Failed to read file");
    });
  });

export const handleConvertCSVToArray = (text: string) => {
  const [head, ...body] = text
    .trim()
    .split("\r\n")
    .map((line) => line.split(","));

  const data: any[] = body.map((line) => {
    const value: any = {};

    line.forEach((item, idx) => {
      let key = head[idx].trim().toLowerCase();
      key = key.replace(/\s+/g, "_");

      if (key === "full-time/part-time") {
        key = "employment_type";
      } else if (key === "post_qualification") {
        key = "post_graduate_qualification";
      } else if (key === "year_of_qulification") {
        key = "year_of_qualification";
      }

      if (
        [
          "school_id",
          "department_id",
          "year_of_enrollment",
          "role_id",
        ].includes(key)
      ) {
        value[key] = Number(item);
      } else if (key === "is_student") {
        value[key] = item.toLowerCase() === "true";
      } else {
        value[key] = item.trim();
      }
    });

    return value;
  });

  return data;
};

export const formatReadableDate = (isoDateString: string) => {
  const date = new Date(isoDateString);

  // Format the date and time separately
  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long", // e.g., October
    day: "numeric", // e.g., 10
  });

  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true, // Optional, for 12-hour format (AM/PM)
  });

  return `${formattedDate} at ${formattedTime}`;
};

export const addTimestamp = (variable: string) => {
  const timestamp = new Date().toISOString(); // Get the current timestamp in ISO format
  return `${variable}_${timestamp}`;
};

export const removeTimestamp = (variableWithTimestamp: string | null) => {
  // Use a regular expression to remove the timestamp pattern (underscore followed by ISO datetime)
  if (variableWithTimestamp !== null) {
    return variableWithTimestamp.replace(
      /_\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/,
      ""
    );
  }
};
