import { useState, useRef } from "react";
import { Button } from "components/Form";
import { useLocation, useParams } from "react-router-dom";

const ExtBankBranch = () => {
  const { id } = useParams();
  const [secret] = useState(id);

  const location = useLocation();
  const { name, studentId } = location.state;

  const instructions = [
    {
      name: "PayDirect",
      action: "Teller logs in PayDirect online",
      color: "#FFE2E5", // Light Red
      text: "#FA5A7D", // Red text
    },
    {
      name: "Search",
      action: "Teller searches for Kano State Tertiary Institution",
      color: "#FFF4DE", // Light Yellow
      text: "#FF947A", // Orange text
    },
    {
      name: "Submit",
      action: "Teller enters reference id and submits payment",
      color: "#DCFCE7", // Light Green
      text: "#3CD856", // Green text
    },
  ];

  const componentRef = useRef<HTMLDivElement>(null);

  // Function to handle printing
  const handlePrint = () => {
    window.print(); // Native print functionality
  };

  return (
    <div className="flex flex-col items-center">
      <div
        ref={componentRef}
        className="printable bg-white my-8 rounded-xl border-[1px] border-gray-300 p-8 flex flex-col gap-8 max-w-[80vw]"
      >
        <h2 className="text-3xl font-semibold">Bank Branch Payment</h2>
        <p className="text-lg text-gray-600 mb-4">
          Please follow the instructions below to make a payment at any bank:
        </p>

        {/* Instructions with Circles */}
        <div className="mb-6 grid grid-cols-1 sm:grid-cols-3 gap-6">
          {instructions.map((instruction, index) => (
            <div key={index} className="flex flex-col items-center">
              <div
                className="flex flex-col items-center justify-center w-[115px] h-[115px] rounded-full"
                style={{ backgroundColor: instruction.color }}
              >
                <span className="font-bold" style={{ color: instruction.text }}>
                  {index + 1}
                </span>
              </div>
              <div className="flex flex-col items-center justify-center mt-[15px]">
                <span className="font-medium">{instruction.name}</span>
                <span className="text-sm">{instruction.action}</span>
              </div>
            </div>
          ))}
        </div>
        <p className="text-lg font-semibold text-gray-700 mt-2 text-center">
          Payment Reference ID:
        </p>
        <div className="mt-6 space-y-2">
          <p>
            Name: <span className="text-red-500">{name}</span>
          </p>
          <p>
            StudentID: <span className="text-red-500">{studentId}</span>
          </p>
        </div>
        <p className="text-2xl font-semibold text-gray-700 text-center">
          {secret}
        </p>
        <Button
          label="Print and Finish"
          className="w-full mt-8 print:hidden"
          onClick={handlePrint}
        />
      </div>
    </div>
  );
};

export default ExtBankBranch;
