import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { executeGetExtTransactionsDetails } from "apis/schools";
import { Button } from "components/Form";
import Loader from "components/Notifications/Loader";
import { useAppSelector } from "hooks/redux";
import { IoCalendarNumber } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { addTimestamp, removeTimestamp } from "utils/index";
import { useSubmitPaymentDetails } from "apis/students";
import toast from "react-hot-toast";

declare global {
  interface Window {
    webpayCheckout: (request: any) => void;
  }
}

interface FormData {
  name?: string;
  regNumber?: string;
  department?: string;
  level?: string;
  faculty?: string;
  txn_ref: string;
  amount: number;
  merchant_code: string;
  pay_item_id: string;
  currency: number;
  site_redirect_url: string;
  split_accounts?: string;
}

const ExtPaymentSummary: React.FC = () => {
  const { id } = useParams();
  const [selectedMethod, setSelectedMethod] = useState<string | null>(null);
  const token = useAppSelector((state) => state.account.token.value);
  const [formData, setFormData] = useState<FormData | null>(null);
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const { mutate: submitPayment } = useSubmitPaymentDetails();

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["extTransactionDetails", token],
    queryFn: () =>
      executeGetExtTransactionsDetails({
        queryKey: ["extTransactionDetails", id!],
        token,
      }),
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      const splitPayload = data?.paymentItem?.consultanceFee
        ? (() => {
            const payproPercentage = 0.7;
            const consultancyPercentage =
              data?.paymentItem?.consultancyPercentage || 0;
            const govtPercentage = 24.825;

            // Calculate institution percentage and round to 3 decimal places
            let institutionPercentage = +(
              100 -
              payproPercentage -
              govtPercentage -
              consultancyPercentage
            ).toFixed(3);

            // Adjust for any remaining difference to ensure exact 100%
            const totalBeforeAdjustment =
              payproPercentage +
              govtPercentage +
              consultancyPercentage +
              institutionPercentage;

            if (totalBeforeAdjustment !== 100) {
              const difference = 100 - totalBeforeAdjustment;
              institutionPercentage = +(
                institutionPercentage + difference
              ).toFixed(3);
            }

            const payload = [
              {
                alias: "Merchant Account",
                percentage: govtPercentage.toFixed(3),
                description: "tuition",
                isPrimary: "true",
              },
              {
                alias: data?.school?.allias,
                percentage: institutionPercentage.toFixed(3),
                description: "tuition",
              },
              {
                alias: "paypro",
                percentage: payproPercentage.toFixed(3),
                description: "tuition",
              },
              {
                alias: "consultancy",
                percentage: consultancyPercentage.toFixed(3),
                description: "consultancy",
              },
            ];

            // Verify total
            const total = payload.reduce(
              (sum, item) => sum + parseFloat(item.percentage),
              0
            );

            console.log("Individual percentages:", {
              govtPercentage: govtPercentage.toFixed(3),
              institutionPercentage: institutionPercentage.toFixed(3),
              payproPercentage: payproPercentage.toFixed(3),
              consultancyPercentage: consultancyPercentage.toFixed(3),
              total: total.toFixed(3),
            });

            return JSON.stringify(payload);
          })()
        : (() => {
            const payproPercentage = 0.7;
            const govtPercentage = 24.825;

            // Calculate institution percentage and round to 3 decimal places
            let institutionPercentage = +(
              100 -
              payproPercentage -
              govtPercentage
            ).toFixed(3);

            // Adjust for any remaining difference to ensure exact 100%
            const totalBeforeAdjustment =
              payproPercentage + govtPercentage + institutionPercentage;

            if (totalBeforeAdjustment !== 100) {
              const difference = 100 - totalBeforeAdjustment;
              institutionPercentage = +(
                institutionPercentage + difference
              ).toFixed(3);
            }

            const payload = [
              {
                alias: "Merchant Account",
                percentage: govtPercentage.toFixed(3),
                description: "tuition",
                isPrimary: "true",
              },
              {
                alias: data?.school?.allias,
                percentage: institutionPercentage.toFixed(3),
                description: "tuition",
              },
              {
                alias: "paypro",
                percentage: payproPercentage.toFixed(3),
                description: "tuition",
              },
            ];

            const total = payload.reduce(
              (sum, item) => sum + parseFloat(item.percentage),
              0
            );

            console.log("Individual percentages:", {
              govtPercentage: govtPercentage.toFixed(3),
              institutionPercentage: institutionPercentage.toFixed(3),
              payproPercentage: payproPercentage.toFixed(3),
              total: total.toFixed(3),
            });

            return JSON.stringify(payload);
          })();

      setFormData({
        name: `${data?.firstName} ${data?.lastName}`,
        regNumber: data?.regNo,
        department: data?.department,
        level: data?.level,
        faculty: data.faculty,
        txn_ref: data?.txnRef,
        amount: Number(data.amount * 100),
        merchant_code: process.env.REACT_APP_MERCHANT_CODE!,
        pay_item_id: process.env.REACT_APP_PAYMENT_ITEM_ID!,
        currency: 566,
        site_redirect_url: `${process.env.REACT_APP_API_URL}/api/v1/payment-confirmation`,
        split_accounts: splitPayload,
      });

      setRedirectUrl(data?.redirectUrl);
    }
  }, [data]);

  console.log({ data });

  if (isLoading) return <Loader />;
  if (isError) {
    console.error("Error fetching invoice details:", error);
    return <div>Error loading invoice details.</div>;
  }

  const getPaymentMethod = () => {
    const cardMethod = {
      label: "Web Pay",
      icon: <IoCalendarNumber />,
      value: "card",
      bgColor: "bg-[#F3FBFF]",
    };

    const bankMethod = {
      label: "Pay with Bank Branch",
      icon: <IoCalendarNumber />,
      value: "bank",
      bgColor: "bg-[#FFF4EE]",
    };

    const quicktellerMethod = {
      label: "Pay with Quickteller",
      icon: <IoCalendarNumber />,
      value: "quickteller",
      bgColor: "bg-[#FFF4EE]",
    };

    return !data?.paymentItem
      ? [bankMethod, quicktellerMethod]
      : data.paymentItem.consultanceFee === null
      ? [bankMethod, quicktellerMethod, cardMethod]
      : [cardMethod];
  };

  const handleMethodSelect = (method: string | null) => {
    setSelectedMethod(method);
  };

  const handlePayment = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedMethod === "card" && formData) {
      // Verify split percentages before proceeding
      const splitAccounts = JSON.parse(formData.split_accounts || "[]");
      const totalPercentage = splitAccounts.reduce(
        (sum: number, account: any) => sum + parseFloat(account.percentage),
        0
      );

      console.log("Final total percentage:", totalPercentage.toFixed(2));

      if (Math.abs(totalPercentage - 100) > 0.01) {
        console.error("Split percentages don't sum to 100%:", totalPercentage);
        return;
      }

      const paymentCallback = async (response: any) => {
        try {
          // Handle successful payment
          if (response.resp === "00") {
            const confirmationData = {
              txnRef: removeTimestamp(response.txnref),
              paymentItemId: data?.paymentItemId,
            };

            // Call your payment confirmation endpoint
            submitPayment(confirmationData, {
              onSuccess: (data) => {
                console.log("Payment confirmation successful:", data);
                if (redirectUrl) {
                  window.location.href = `${redirectUrl}?txnRef=${removeTimestamp(
                    response.txnref
                  )}&status=successful`;
                } else {
                  toast.error(
                    "Redirect URL not found. Please contact support."
                  );
                  console.error("redirectUrl not available");
                }
              },
              onError: (error) => {
                console.error("Payment confirmation failed:", error);
                if (redirectUrl) {
                  window.location.href = `${redirectUrl}?txnRef=${removeTimestamp(
                    response.txnref
                  )}&status=failed`;
                } else {
                  toast.error("An error occurred during payment confirmation");
                  console.error("redirectUrl not available");
                }
              },
            });
          } else {
            // Handle failed payment
            console.error("Payment failed:", response);
            if (redirectUrl) {
              window.location.href = `${redirectUrl}?txnRef=${removeTimestamp(
                response.txnref
              )}&status=failed`;
            } else {
              window.location.href = `/payment-failed/${response.txnref}`;
            }
          }
        } catch (error) {
          console.error("Error in payment confirmation:", error);
          if (redirectUrl) {
            window.location.href = `${redirectUrl}?txnRef=${removeTimestamp(
              response.txnref || formData.txn_ref
            )}&status=failed`;
          } else {
            window.location.href = `/payment-failed/${
              response.txnref || formData.txn_ref
            }`;
          }
        }
      };

      const paymentRequest = {
        amount: formData.amount,
        currency: formData.currency,
        merchant_code: formData.merchant_code,
        pay_item_id: formData.pay_item_id,
        split_accounts: formData.split_accounts,
        txn_ref: addTimestamp(formData.txn_ref),
        site_redirect_url: formData.site_redirect_url,
        mode: "LIVE",
        onComplete: paymentCallback,
      };

      console.log("Payment Request:", paymentRequest);
      console.log("Split Accounts:", paymentRequest.split_accounts);

      window.webpayCheckout(paymentRequest);
    } else if (selectedMethod === "bank") {
      navigate(`/ext-bank-branch/${data?.txnRef}`, {
        state: {
          name: `${data?.firstName} ${data?.lastName}`,
          studentId: data?.regNo,
        },
      });
    } else {
      navigate(`/quickteller/${data?.txnRef}`, {
        state: {
          referenceId: data?.txnRef,
          name: `${data?.firstName} ${data?.lastName}`,
          studentId: data?.regNo,
          paymentUrl: "https://www.quickteller.com/KANOSTATETERTIARY",
        },
      });
    }
  };

  return (
    <div className="flex flex-col items-center">
      <div className="bg-white my-8 rounded-xl border-[1px] border-border p-5 max-w-[80vw] py-[20px] w-1/2">
        <h2 className="text-2xl font-semibold mb-6">Complete Payment</h2>

        {formData && (
          <form onSubmit={handlePayment}>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-8">
              <FormField label="Name" value={formData.name} />
              <FormField label="Reg Number" value={formData.regNumber} />
              <FormField label="Department" value={formData.department} />
              <FormField label="Level" value={formData.level} />
              <FormField label="Faculty" value={formData?.faculty} />
              <FormField
                label="Transaction Reference"
                value={formData.txn_ref}
              />
              <FormField label="Amount" value={`₦${formData.amount / 100}`} />
            </div>

            <h2 className="text-2xl font-semibold mb-6">Payment method</h2>
            <div className="flex gap-8 mb-8">
              {getPaymentMethod().map((method, index) => (
                <div
                  key={index}
                  className={`flex gap-2 items-center p-6 cursor-pointer transition duration-300 
                  ${method.bgColor} 
                  ${
                    selectedMethod === method.value
                      ? "border-2 border-red-500"
                      : ""
                  }
                `}
                  onClick={() => handleMethodSelect(method.value)}
                >
                  {method.icon}
                  <div>{method.label}</div>
                </div>
              ))}
            </div>

            <Button
              type="submit"
              label={
                selectedMethod === "card" ? "Pay with Interswitch" : "Next"
              }
              className="w-full"
              disabled={!selectedMethod}
            />
          </form>
        )}
      </div>
    </div>
  );
};

export default ExtPaymentSummary;

export const FormField: React.FC<{
  label: string;
  value?: string | number;
}> = ({ label, value }) => (
  <div className="flex flex-col mb-4">
    <label className="text-sm font-medium text-gray-600 mb-1">{label}</label>
    <div className="border border-gray-400 rounded-md p-3 text-gray-600 bg-gray-50">
      {value}
    </div>
  </div>
);
