import { BrowserRouter, Route, Routes } from "react-router-dom";
import Aos from "aos";
import Toast from "components/Notifications/Toast";
import Payments from "screens/Payments/Payments";
import Appointments from "screens/Appointments";
import Campaigns from "screens/Campaings";
import Services from "screens/Services";
import Invoices from "screens/Invoices/Invoices";
import Settings from "screens/Settings";
import CreateInvoice from "screens/Invoices/CreateInvoice";
import EditInvoice from "screens/Invoices/EditInvoice";
import PreviewInvoice from "screens/Invoices/PreviewInvoice";
import EditPayment from "screens/Payments/EditPayment";
import PreviewPayment from "screens/Payments/PreviewPayment";
import Medicine from "screens/Medicine";
import PatientProfile from "screens/WebPay/PatientProfile";
import CreatePatient from "screens/WebPay/CreatePatient";
import DoctorProfile from "screens/Doctors/DoctorProfile";
import BankChannel from "screens/BankChannel";
import NewMedicalRecord from "screens/WebPay/NewMedicalRecode";
import NotFound from "screens/NotFound";
import AdminLogin from "screens/Auth/AdminLogin";
import WebPay from "screens/WebPay/WebPay";
import ProtectedRoute from "routes/ProtectedRoute";
import Login from "screens/Login";
import History from "screens/Payments/History";
import DuePayments from "screens/Payments/DuePayments";
import StudentDashboard from "screens/Dashboards/StudentDashboard";
import AdminDashboard from "screens/Dashboards/AdminDashboard";
import { useAppSelector } from "hooks/redux";
import StudentTable from "screens/Student/StudentTable";
import PaymentItemsTable from "screens/Payments/PaymentItemsTable";
import ExecutiveDashboard from "screens/Dashboards/ExecutiveDashboard";
import EmailVerification from "screens/Auth/EmailVerification";
import SetUserPassword from "screens/Auth/SetPassword";
import PaymentSummary from "screens/Payments/PaymentSummary";
import ExtPaymentSummary from "screens/Payments/ExtPaymentSummary";
import BankBranch from "screens/Payments/BankBranch";
import PaymentConfirmation from "screens/Payments/PaymentConfirmation";
import ExtBankBranch from "screens/Payments/ExtBankBranch";
import ExternalPaymentConfirmation from "screens/Payments/ExtPaymentConfirmation";
import QuicktellerPaymentPage from "screens/Payments/Quickteller";

type route = {
  path: string;
  element: JSX.Element;
  name: string;
  protected: boolean;
  children?: any[];
  requiredModule?: string;
};

type Roles = "admin" | "student" | "executive" | "super admin";

const routes = (roleName: string | Roles): route[] => [
  {
    path: "/",
    element:
      roleName === "student" ? (
        <StudentDashboard />
      ) : roleName === "executive" ? (
        <ExecutiveDashboard />
      ) : (
        <AdminDashboard />
      ),
    name: "Dashboard",
    protected: true,
  },
  {
    path: "/email-verification",
    element: <EmailVerification />,
    name: "Email Verification",
    protected: false,
  },
  {
    path: "/set-password",
    element: <SetUserPassword />,
    name: "Set Password",
    protected: false,
  },
  {
    path: "/invoices",
    element: <Invoices />,
    name: "Invoices",
    protected: true,
  },
  {
    path: "/invoices/create",
    element: <CreateInvoice />,
    name: "Create Invoice",
    protected: true,
  },
  {
    path: "/invoices/edit/:id",
    element: <EditInvoice />,
    name: "Edit Invoice",
    protected: true,
  },
  {
    path: "/invoices/preview/:id",
    element: <PreviewInvoice />,
    name: "Preview Invoice",
    protected: true,
  },
  {
    path: "/payments",
    element: <Payments />,
    name: "Payments",
    protected: true,
  },
  {
    path: "/payments/edit/:id",
    element: <EditPayment />,
    name: "Edit Payment",
    protected: true,
  },
  {
    path: "/payments/preview/:id",
    element: <PreviewPayment />,
    name: "Preview Payment",
    protected: true,
  },
  { path: "/web-pay", element: <WebPay />, name: "WebPay", protected: true },
  {
    path: "/patients/preview/:id",
    element: <PatientProfile />,
    name: "Patient Profile",
    protected: true,
  },
  {
    path: "/patients/create",
    element: <CreatePatient />,
    name: "Create Patient",
    protected: true,
  },
  {
    path: "/patients/visiting/:id",
    element: <NewMedicalRecord />,
    name: "New Medical Record",
    protected: true,
  },
  {
    path: "/all-payments",
    element: <Payments />,
    name: "All Payments",
    protected: true,
  },

  {
    path: "/payment-history",
    element: <History />,
    name: "Payment History",
    protected: true,
  },

  {
    path: "/due-payments",
    element: <DuePayments />,
    name: "Due Payments",
    protected: true,
  },
  {
    path: "/payment-summary/:id",
    element: <PaymentSummary />,
    name: "Payment Summary",
    protected: true,
  },
  {
    path: "/ext-payment-summary/:id",
    element: <ExtPaymentSummary />,
    name: "External Payment Summary",
    protected: false,
  },
  {
    path: "/bank-branch/:id",
    element: <BankBranch />,
    name: "Payment Summary",
    protected: true,
  },

  {
    path: "/ext-bank-branch/:id",
    element: <ExtBankBranch />,
    name: "External Bank Payment",
    protected: false,
  },
  {
    path: "/payment-items",
    element: <PaymentItemsTable />,
    name: "Payment Items",
    protected: true,
  },
  {
    path: "/students",
    element: <StudentTable />,
    name: "Students",
    protected: true,
  },
  {
    path: "/doctors/preview/:id",
    element: <DoctorProfile />,
    name: "Doctor Profile",
    protected: true,
  },
  {
    path: "/bank-channel",
    element: <BankChannel />,
    name: "Bank Channel",
    protected: true,
  },

  {
    path: "/quickteller/:id",
    element: <QuicktellerPaymentPage />,
    name: "Quickteller Channel",
    protected: false,
  },
  {
    path: "/payment-confirmation",
    element: <PaymentConfirmation />,
    name: "Payment Confirmation",
    protected: true,
  },
  {
    path: "/external-payment-confirmation",
    element: <ExternalPaymentConfirmation />,
    name: "Payment Confirmation",
    protected: false,
  },
  { path: "/login", element: <Login />, name: "Login", protected: false },
  {
    path: "/admin-login",
    element: <AdminLogin />,
    name: "Admin Login",
    protected: false,
  },
  {
    path: "/appointments",
    element: <Appointments />,
    name: "Appointments",
    protected: true,
  },
  {
    path: "/campaigns",
    element: <Campaigns />,
    name: "Campaigns",
    protected: true,
  },
  {
    path: "/medicine",
    element: <Medicine />,
    name: "Medicine",
    protected: true,
  },
  {
    path: "/services",
    element: <Services />,
    name: "Services",
    protected: true,
  },
  {
    path: "/settings",
    element: <Settings />,
    name: "Settings",
    protected: true,
  },
  { path: "*", element: <NotFound />, name: "Not Found", protected: false },
];

function App() {
  Aos.init();
  const role = useAppSelector((state) => state.account.role);

  return (
    <>
      <Toast />
      <BrowserRouter>
        <Routes>
          {routes(role?.roleName).map((route, idx) => {
            if (route.children) {
              return (
                <Route key={idx} element={route.element} path={route.path}>
                  {route.children.map((item, index) => (
                    <Route
                      key={index}
                      element={
                        item.protected ? (
                          <ProtectedRoute requiredModule={route.requiredModule}>
                            {item.element}
                          </ProtectedRoute>
                        ) : (
                          item.element
                        )
                      }
                      path={item.path}
                    />
                  ))}
                </Route>
              );
            }
            return (
              <Route
                key={idx}
                element={
                  route.protected ? (
                    <ProtectedRoute requiredModule={route.requiredModule}>
                      {route.element}
                    </ProtectedRoute>
                  ) : (
                    route.element
                  )
                }
                path={route.path}
              />
            );
          })}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
